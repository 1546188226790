import React from 'react'
import img1 from '../assets/images/img2.jpg'

const CardComponent = (props) => {
    return (
        <div className="movie_card_container">  
            <img src={img1} className="img_wrapper"/>
            <div className="card_body">
            <span>{props.name}</span>
            <span>Movie Star : {props.star}</span>
            </div>
        </div>
    )
}

export default CardComponent