import React from 'react'
import './assets/style.css'
import CardComponent from './components/cardComponent'
import Header from './components/Header'
import {MovieData} from './assets/utils'

class MovieApp extends React.Component {  
  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className="main_wrapper">
       <Header />
        <div className="movie_card_list">
           <h2 className="top-heading"> Movie list </h2>
            <div className="movie_card_wrap">
                {MovieData.map((data, index)=>(
                  <CardComponent name={data.mname} star={data.mstar} key={index}/>
                ))}
            </div>
        </div>
      </div>
    )
  }
}

export default MovieApp;
