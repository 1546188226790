export const MovieData = [
    {mname: "Joker", mstar: "5"},
    {mname: "Raabta", mstar: "3"},
    {mname: "Kaabil", mstar: "5"},
    {mname: "Badhaai ho", mstar: "4"},
    {mname: "Raanjhanaa", mstar: "4"},
    {mname: "Raanjhanaa", mstar: "4"},
    {mname: "Raanjhanaa", mstar: "4"},
    {mname: "Raanjhanaa", mstar: "4"},
    {mname: "Raanjhanaa", mstar: "4"}
]